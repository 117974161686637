/*
 * Author: dizhong zhu
 * Date: 18/03/2025
 */

import React, { useState } from 'react'
import { EyeIcon, EyeSlashIcon, ClipboardIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'

interface Props {
    label: string
    value: string
    controlId: string
    readOnly?: boolean
    helpText?: string
}

export const PasswordInput: React.FC<Props> = ({ label, value, controlId, readOnly = false, helpText }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [copied, setCopied] = useState(false)

    const handleCopy = async () => {
        await navigator.clipboard.writeText(value)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    return (
        <div className="space-y-3">
            <label htmlFor={controlId} className="block text-lg font-medium text-gray-900 dark:text-white">
                {label}
            </label>
            <div className="relative">
                <input
                    type={showPassword ? 'text' : 'password'}
                    id={controlId}
                    value={value}
                    readOnly={readOnly}
                    className="w-full px-4 py-2 pr-24 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-1">
                    <button
                        type="button"
                        onClick={handleCopy}
                        className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
                        title="Copy to clipboard"
                    >
                        {copied ? <ClipboardDocumentCheckIcon className="w-5 h-5 text-green-500" /> : <ClipboardIcon className="w-5 h-5" />}
                    </button>
                    <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
                        title={showPassword ? 'Hide password' : 'Show password'}
                    >
                        {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                    </button>
                </div>
            </div>
            {helpText && <p className="text-sm text-gray-500 dark:text-gray-400">{helpText}</p>}
        </div>
    )
}
