/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

import { useState } from 'react'
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'

import { GeneralSetting } from './settings/general'
import { TenSettingSizeChart } from './settings/SettingSizeChart/TenSettingSizeChart'
import { AIListSetting } from './settings/ai-listing'
import { TENANT_GENERAL, TENANT_SIZECHART, TENANT_AI_LISTING } from 'common/routePaths'

function TenantSettings() {
    const location = useLocation()
    const domain = location.state?.domain
    const navigate = useNavigate()

    const sideBarItems = [
        { name: 'General', path: TENANT_GENERAL, component: GeneralSetting },
        { name: 'Size Chart', path: TENANT_SIZECHART, component: TenSettingSizeChart },
        { name: 'AI Listing', path: TENANT_AI_LISTING, component: AIListSetting },
    ]
    const [currentItem, setCurrentItem] = useState(sideBarItems[0].name)

    const handleSideBarClick = (item: any) => {
        setCurrentItem(item.name)
        navigate(item.path, { state: { domain } })
    }

    return (
        <div className="flex h-full w-full bg-background">
            {/* Domain Title Banner */}
            <div className="fixed top-0 left-0 right-0 z-10 bg-gradient-to-r from-primary/10 to-primary/5 border-b border-primary/20 backdrop-blur-sm">
                <div className="container mx-auto px-6 py-3 flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <h1 className="text-xl font-semibold text-foreground">
                            <span className="text-muted-foreground">Settings:</span> {domain || 'Not specified'}
                        </h1>
                    </div>
                </div>
            </div>

            <div className="flex min-h-screen w-full pt-0">
                {/* Sidebar - Made fixed */}
                <div className="w-64 border-r border-border bg-card/40 backdrop-blur-sm sticky top-12 self-start h-[calc(100vh-3rem)]">
                    <div className="absolute inset-0 bg-gradient-to-b from-primary/5 to-primary/10"></div>
                    <div className="absolute inset-0 opacity-10 bg-[radial-gradient(#808080_1px,transparent_1px)] [background-size:16px_16px]"></div>
                    <div className="h-full py-0 relative overflow-y-auto">
                        <nav className="space-y-1.5 px-3 pt-3">
                            {sideBarItems.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleSideBarClick(item)}
                                    className={`w-full flex items-center px-4 py-2.5 text-sm font-medium rounded-lg transition-all duration-200 ease-in-out
                                        ${
                                            currentItem === item.name
                                                ? 'bg-primary/15 text-primary shadow-lg shadow-primary/20 border border-primary/20'
                                                : 'text-muted-foreground hover:bg-primary/5 hover:text-foreground hover:shadow-sm'
                                        }`}
                                >
                                    {item.name}
                                </button>
                            ))}
                        </nav>
                    </div>
                </div>
                {/* Main Content - Only this will scroll */}
                <div className="flex-1 overflow-y-auto p-6 pt-3">
                    <Routes>
                        <Route path="/" element={<GeneralSetting domain={domain} />} />
                        {sideBarItems.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component domain={domain} />} />
                        ))}
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export { TenantSettings }
