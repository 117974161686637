/*
 * Author: dizhong zhu
 * Date: 09/08/2024
 */
import React from 'react'

interface DividerProps {
    className: string
}

export const Divider = ({ className }: DividerProps) => {
    const dividerStyle = {
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    }

    return <div className={`divider ${className}`} style={dividerStyle}></div>
}
