import React, { useEffect, useState } from 'react'
import { TenantT } from 'store/tenant'
import { PasswordInput } from 'widgets/passwordInput'

interface Props {
    info: TenantT | null
}

export const TenantInfo: React.FC<Props> = ({ info }) => {
    const [formValues, setFormValues] = useState({
        name: info?.name || '',
        description: info?.description || '',
        domain: info?.domain || '',
        apikey: info?.apikey || '',
    })

    useEffect(() => {
        setFormValues({
            name: info?.name || '',
            description: info?.description || '',
            domain: info?.domain || '',
            apikey: info?.apikey || '',
        })
    }, [info])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setFormValues((prevState) => ({ ...prevState, [name]: value }))
    }

    return (
        <div className="max-w-2xl">
            <div className="space-y-8">
                <div className="space-y-3">
                    <label htmlFor="name" className="block text-lg font-medium text-gray-900 dark:text-white">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                        placeholder="Enter name"
                    />
                    <p className="text-sm text-gray-500 dark:text-gray-400">Give a name to your tenant store</p>
                </div>

                <div className="space-y-3">
                    <label htmlFor="description" className="block text-lg font-medium text-gray-900 dark:text-white">
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formValues.description}
                        onChange={handleInputChange}
                        rows={4}
                        className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors resize-none"
                        placeholder="Enter description"
                    />
                </div>

                <div className="space-y-3">
                    <label className="block text-lg font-medium text-gray-900 dark:text-white">Your Subscription Plan</label>
                    <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-2xl font-bold text-blue-600 dark:text-blue-400">{info?.plan}</p>
                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Current subscription</p>
                            </div>
                        </div>
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Or change your plan in the billing page</p>
                </div>

                <div className="space-y-3">
                    <PasswordInput controlId="api_key" label="API KEY" value={formValues.apikey} readOnly helpText="This is the API key for your store, please do not share it with anyone" />
                </div>
            </div>
        </div>
    )
}
