/*
 * Author: dizhong zhu
 * Date: 19/03/2025
 */

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ConfigurationT, ListConfigurationResponseT } from 'types/configurations'
import { getConfigurationsRequest, updateConfigurationRequest } from 'services/configuration'

interface AIListingState {
    configurations: ConfigurationT[]
    nodeConfiguration: any | null
    taggingSchema: any | null
    isLoadingAIListing: boolean
    aiListingError: any[]
}

export const initialState: AIListingState = {
    configurations: [],
    nodeConfiguration: null,
    taggingSchema: null,
    isLoadingAIListing: false,
    aiListingError: [],
}

interface ConfigurationPayloadT {
    domain: string
    value: any
}

const updateConfiguration = createAsyncThunk<any, any>('configuration/update', async (i: any, { dispatch }) => {
    dispatch(isLoadingAIListing(true))
    dispatch(setAIListingError([]))

    const { domain, data } = i

    return updateConfigurationRequest(domain, data)
        .then((response: any) => {
            dispatch(getAIListingConfiguration(domain))
            return response.data
        })
        .catch((err: any) => {
            dispatch(setAIListingError([err.response.data.message]))
        })
        .finally(() => dispatch(isLoadingAIListing(false))) as Promise<any>
})

export const updateNodeConfiguration = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/node', async ({ domain, value }: any, { dispatch }) => {
    dispatch(updateConfiguration({ domain, data: { name: 'node_config', value: value } }))
    return {}
})

export const updateTaggingSchema = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/tagging-schema', async ({ domain, value }: any, { dispatch }) => {
    dispatch(updateConfiguration({ domain, data: { name: 'tagging_schema', value: value } }))
    return {}
})

export const getAIListingConfiguration = createAsyncThunk<ConfigurationT[], string>('configuration/view/ai-listing', async (domain: string, { dispatch }) => {
    dispatch(isLoadingAIListing(true))
    dispatch(setAIListingError([]))

    return getConfigurationsRequest(domain)
        .then((res: ListConfigurationResponseT) => {
            const nodeConfig = res.data.find((config: ConfigurationT) => config.name === 'node_config')
            const taggingSchema = res.data.find((config: ConfigurationT) => config.name === 'tagging_schema')
            dispatch(setNodeConfiguration(nodeConfig?.value))
            dispatch(setTaggingSchema(taggingSchema?.value))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setAIListingError([err.response?.data?.message]))
        })
        .finally(() => dispatch(isLoadingAIListing(false))) as Promise<ConfigurationT[]>
})

export const aiListingConfigurationSlice = createSlice({
    name: 'aiListing',
    initialState,
    reducers: {
        setConfigurations: (state, { payload }: PayloadAction<ConfigurationT[]>) => {
            state.configurations = payload
        },
        setNodeConfiguration: (state, { payload }: PayloadAction<any>) => {
            state.nodeConfiguration = payload
        },
        setTaggingSchema: (state, { payload }: PayloadAction<any>) => {
            state.taggingSchema = payload
        },
        setAIListingError: (state, { payload }: PayloadAction<any[]>) => {
            state.aiListingError = payload
        },
        isLoadingAIListing: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoadingAIListing = payload
        },
    },
})

export const { setConfigurations, setNodeConfiguration, setTaggingSchema, setAIListingError, isLoadingAIListing } = aiListingConfigurationSlice.actions

export default aiListingConfigurationSlice.reducer
