/*
 * Author: dizhong zhu
 * Date: 21/02/2025
 */

import { GTQueueStatusT } from '../../types/garment-tagging'
import React from 'react'

interface Props {
    status: GTQueueStatusT
}

export const QueueStatusBanner: React.FC<Props> = ({ status }) => {
    const getStatusStyles = (status: GTQueueStatusT): string => {
        const baseClasses = 'px-3 py-1 rounded-full text-sm font-medium'

        switch (status) {
            case 'complete':
                return `${baseClasses} bg-green-100 text-green-800`
            case 'failed':
                return `${baseClasses} bg-red-100 text-red-800`
            case 'processing':
                return `${baseClasses} bg-blue-100 text-blue-800`
            case 'queuing':
                return `${baseClasses} bg-yellow-100 text-yellow-800`
            default:
                return `${baseClasses} bg-gray-100 text-gray-800`
        }
    }

    if (!status) {
        return null
    }

    return <span className={getStatusStyles(status)}>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
}
