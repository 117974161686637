/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { getTenant } from 'store/tenant'
import { getGeneralConfigurations } from 'store/configurations/general'
import { TenantInfo } from './tenantInfo'
import { WidgetConfiguration } from './widgetConfig'
import { DangerousZone } from './dangerousZone'

interface Props {
    domain: string
}

export const GeneralSetting: React.FC<Props> = ({ domain }) => {
    const dispatch = useAppDispatch()

    const { activeTenant } = useAppSelector((state) => state.tenant)
    const { configurations } = useAppSelector((state) => state.generalConfiguration)

    useEffect(() => {
        dispatch(getTenant(domain))
        dispatch(getGeneralConfigurations(domain))
    }, [])

    return (
        <div className="w-full px-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md mb-6 overflow-hidden">
                <div className="p-6">
                    <h4 className="text-xl font-semibold text-gray-800 dark:text-white flex items-center">
                        Tenant Information:
                        <span className="ml-2 text-blue-600 dark:text-blue-400">{activeTenant?.domain}</span>
                    </h4>
                    <div className="h-px w-full bg-gray-200 dark:bg-gray-700 my-4" />
                    <TenantInfo info={activeTenant} />

                    <h4 className="text-xl font-semibold text-gray-800 dark:text-white mt-12">Widget configurations</h4>
                    <div className="h-px w-full bg-gray-200 dark:bg-gray-700 my-4" />
                    <WidgetConfiguration domain={domain} configs={configurations} />

                    <h4 className="text-xl font-semibold text-gray-800 dark:text-white mt-12">Dangerous</h4>
                    <div className="h-px w-full bg-gray-200 dark:bg-gray-700 my-4" />
                    <DangerousZone domain={domain} />
                </div>
            </div>
        </div>
    )
}
