/*
 * Author: dizhong zhu
 * Date: 10/03/2023
 */

import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormInput, FormInputArea } from 'widgets/FormInput'
import { Api_CreateTenant, Api_DeleteTenant } from 'apis/tenants'
import { APIErrorHandler } from 'apis/apiErros'

interface ModalTenantT {
    name: string
    domain: string
    description: string
}

interface ModalCreateProps {
    show: boolean
    onHide: () => void
}

const ModalCreate: React.FC<ModalCreateProps> = ({ show, onHide }) => {
    const [tenant, setTenant] = useState<ModalTenantT>({
        name: '',
        domain: '',
        description: '',
    })

    const resetForm = () => {
        setTenant({
            name: '',
            domain: '',
            description: '',
        })
    }

    const handleError = APIErrorHandler()

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.currentTarget
        setTenant((prevTenant) => ({ ...prevTenant, [name]: value }))
        // const updatedTenant = { ...tenant, [input.name]: input.value }
        //
        // setTenant(updatedTenant)
    }

    const handleCreateTenant = async (event: FormEvent) => {
        // Code to create a new tenant
        console.log(tenant)
        event.preventDefault()
        // Here you can add your code to handle the sign-up process, like sending the form data to your backend API.
        try {
            const token = localStorage.getItem('token')
            const success = await Api_CreateTenant(tenant, token)
            if (success) {
                resetForm()
                onHide()
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create Tenant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <FormInput name="name" type="text" label="Name" value={tenant.name} onChange={handleInputChange} />
                    <FormInput name="domain" type="text" label="Domain" value={tenant.domain} onChange={handleInputChange} />
                    <FormInputArea name="description" label="Description" rows={3} value={tenant.description} onChange={handleInputChange} />
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-gray-600" variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button className="bg-blue-600" variant="primary" onClick={handleCreateTenant}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

interface ModalDeleteProps {
    show: boolean
    onHide: () => void
    onDelete: () => void
    domain: string
}

const ModalDelete: React.FC<ModalDeleteProps> = ({ show, onHide, onDelete, domain }) => {
    const handleError = APIErrorHandler()

    const handleDeleteTenant = async () => {
        const token = localStorage.getItem('token')
        try {
            const success = await Api_DeleteTenant(domain, token)
            if (success) {
                onDelete()
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Tenant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to delete <strong>{domain}</strong>?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleDeleteTenant}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export { ModalCreate, ModalDelete }
