/*
 * Author: dizhong zhu
 * Date: 30/01/2025
 */

import React, { useEffect, useState } from 'react'
import { TenantSideBar } from 'widgets/TenantSideBar'
import { useAppSelector } from 'store'
import { GarmentTaggingContent } from './content'

export const GarmentTagging = () => {
    const [currentDomain, setCurrentDomain] = useState('')
    const { tenants } = useAppSelector((state) => state.tenant)
    const { isGarmentTaggingLoading } = useAppSelector((state) => state.garmentTagging)

    // Initialize with first tenant if available
    useEffect(() => {
        if (tenants.length > 0 && !currentDomain) {
            setCurrentDomain(tenants[0].domain)
        }
    }, [tenants])

    const handleTenantClick = (domain: string) => {
        setCurrentDomain(domain)
    }

    return (
        <div className="h-[calc(100vh-10rem)] flex overflow-hidden">
            <div className="w-1/6 min-w-[250px] border-r border-gray-200 overflow-y-auto custom-scrollbar">
                <TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick} />
            </div>
            <div className="flex-1 flex flex-col overflow-hidden">{currentDomain && <GarmentTaggingContent domain={currentDomain} />}</div>
        </div>
    )
}
