import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Api_UpdateTenantConfigurationImage, Api_UpdateTenantConfigurationMeasureConfig } from 'apis/tenants'
import { APIErrorHandler } from 'apis/apiErros'
import ReactAce from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/webpack-resolver'
import { fetchImage } from 'utils/data_process'
import { useAppDispatch, useAppSelector } from 'store'
import {
    getGeneralConfigurations,
    setMeasureConfiguration,
    updateButtonHiddenConfiguration,
    updateEmailConfiguration,
    updateReconstructionConfiguration,
    updateVideoConfiguration,
    updateWidgetConfiguration,
} from 'store/configurations/general'
import toast from 'react-hot-toast'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'

interface Props {
    domain: string
    configs: any
}

interface UploadedImageT {
    blob: any
    name: string
    type: string
}

export const WidgetConfiguration: React.FC<Props> = ({ domain, configs }) => {
    const [uploadedImage, setUploadedImage] = useState<UploadedImageT | null>(null)
    const [uploadMeasurementErr, setUploadMeasurementErr] = useState('')
    const [emailList, setEmailList] = useState<string[]>([])
    const [localWidgetConfig, setLocalWidgetConfig] = useState('{}')
    const handleError = APIErrorHandler()

    const { videoConfiguration, reconstructionConfiguration, buttonHiddenConfiguration, widgetConfiguration, measureConfiguration, emailConfiguration } = useAppSelector(
        (state) => state.generalConfiguration
    )
    const dispatch = useAppDispatch()

    const emailListInputRef = useRef<any>(null)

    useEffect(() => {
        const fetchData = async () => {
            const imageConfig = configs?.find((config: any) => config.name === 'image_cover')
            if (imageConfig?.value) {
                try {
                    const image = await fetchImage(imageConfig.value)
                    let processedImageBlob = ''
                    if (image.type === '.svg') {
                        processedImageBlob = image.dataUrl.replace('data:application/octet-stream', 'data:image/svg+xml')
                    } else {
                        processedImageBlob = image.dataUrl.replace('data:application/octet-stream', `data:${image.type}`)
                    }
                    setUploadedImage({
                        blob: processedImageBlob,
                        name: 'image_cover',
                        type: image.type,
                    })
                } catch (error) {
                    console.error('Error fetching image:', error)
                    toast.error('Failed to load cover image')
                }
            }
        }
        fetchData().then()
        setLocalWidgetConfig(JSON.stringify(widgetConfiguration, null, 2))
    }, [configs])

    const handleImageChange = (e: any) => {
        const file = e.target.files?.[0]
        const reader = new FileReader()

        if (file) {
            const fileNameParts = file.name.split('.')
            const extension = '.' + fileNameParts.pop()
            const fileNameWithoutExtension = fileNameParts.join('.')

            reader.onloadend = () => {
                setUploadedImage({
                    blob: reader.result,
                    name: fileNameWithoutExtension,
                    type: extension,
                })
            }
            reader.readAsDataURL(file)
        }
    }

    const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader()
        const { files } = event.target

        if (files?.[0]) {
            fileReader.readAsText(files[0], 'UTF-8')
            fileReader.onload = (e: any) => {
                const content = e.target.result
                try {
                    dispatch(setMeasureConfiguration(JSON.parse(content)))
                    setUploadMeasurementErr('')
                } catch (e) {
                    setUploadMeasurementErr('Please upload a valid json file')
                }
            }
        }
    }

    const uploadImage = async () => {
        try {
            const token = localStorage.getItem('token')
            await Api_UpdateTenantConfigurationImage(domain, token, uploadedImage)
            toast.success('Image uploaded successfully')
        } catch (error) {
            handleError(error)
        }
    }

    // Modify the updateMeasurementConfiguration function
    const updateMeasurementConfiguration = async () => {
        const token = localStorage.getItem('token')
        Api_UpdateTenantConfigurationMeasureConfig(domain, token, measureConfiguration)
            .then(() => {
                toast.success('Measurement configuration updated')
                dispatch(getGeneralConfigurations(domain))
            })
            .catch((err) => {
                handleError(err)
            })
    }

    const onEmailListChange = useCallback((e: any) => {
        const emails = []
        try {
            const data = JSON.parse(e.detail.value)
            for (let i = 0; i < data.length; i++) {
                const email = data[i]
                emails.push(email.value)
            }
            setEmailList(emails)
        } catch (error) {
            setEmailList([])
        }
    }, [])

    const handleWidgetConfigChange = (newConfig: any) => {
        setLocalWidgetConfig(newConfig)
    }

    const updateWidgetConfig = () => {
        try {
            const parsedConfig = JSON.parse(localWidgetConfig)
            dispatch(updateWidgetConfiguration({ domain, value: parsedConfig }))
            toast.success('Widget configuration updated')
        } catch (error) {
            toast.error('Invalid JSON format')
        }
    }

    return (
        <div className="space-y-8">
            {/* Data Email Section */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Data Email</h3>
                <div className="mb-4">
                    <label className="flex items-center cursor-pointer">
                        <div className="relative">
                            <input
                                type="checkbox"
                                className="sr-only"
                                checked={emailConfiguration?.receive_email}
                                onChange={() => {
                                    dispatch(
                                        updateEmailConfiguration({
                                            domain,
                                            value: { receive_email: !emailConfiguration?.receive_email, email_list: emailList },
                                        })
                                    )
                                    toast.success(`Email notifications ${!emailConfiguration?.receive_email ? 'enabled' : 'disabled'}`)
                                }}
                            />
                            <div className={`block w-14 h-8 rounded-full ${emailConfiguration?.receive_email ? 'bg-blue-600' : 'bg-gray-300 dark:bg-gray-600'} transition-colors duration-200`}></div>
                            <div
                                className={`absolute left-1 top-1 bg-white dark:bg-gray-200 w-6 h-6 rounded-full transition-transform duration-200 transform ${emailConfiguration?.receive_email ? 'translate-x-6' : 'translate-x-0'}`}
                            ></div>
                        </div>
                        <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">Receive data when scan completes</span>
                    </label>
                </div>

                {emailConfiguration?.receive_email && (
                    <div className="mt-4">
                        <div className="flex gap-3 items-start">
                            <div className="flex-grow">
                                <Tags tagifyRef={emailListInputRef} defaultValue={emailConfiguration.email_list.join(',')} onChange={onEmailListChange} placeholder="Enter comma separated emails" />
                            </div>
                            <button
                                className={`px-4 py-2 rounded-md text-white ${emailList.length < 1 ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'} transition-colors duration-200`}
                                disabled={emailList.length < 1}
                                onClick={() => {
                                    dispatch(
                                        updateEmailConfiguration({
                                            domain,
                                            value: { receive_email: true, email_list: emailList },
                                        })
                                    )
                                    toast.success('Email list updated successfully')
                                }}
                            >
                                Save
                            </button>
                        </div>
                        <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">You will receive scan data in these emails every time a user completes a scan.</p>
                    </div>
                )}
            </div>

            {/* Video Configuration */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Video Configuration</h3>
                <label className="flex items-center cursor-pointer">
                    <div className="relative">
                        <input
                            type="checkbox"
                            className="sr-only"
                            checked={videoConfiguration?.keep_video}
                            onChange={() => {
                                dispatch(updateVideoConfiguration({ domain, value: { keep_video: !videoConfiguration?.keep_video } }))
                                toast.success(`Video retention ${!videoConfiguration?.keep_video ? 'enabled' : 'disabled'}`)
                            }}
                        />
                        <div className={`block w-14 h-8 rounded-full ${videoConfiguration?.keep_video ? 'bg-blue-600' : 'bg-gray-300 dark:bg-gray-600'} transition-colors duration-200`}></div>
                        <div
                            className={`absolute left-1 top-1 bg-white dark:bg-gray-200 w-6 h-6 rounded-full transition-transform duration-200 transform ${videoConfiguration?.keep_video ? 'translate-x-6' : 'translate-x-0'}`}
                        ></div>
                    </div>
                    <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">Keep video after processing</span>
                </label>
            </div>

            {/* Reconstruction Configuration */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Reconstruction Configuration</h3>
                <label className="flex items-center cursor-pointer">
                    <div className="relative">
                        <input
                            type="checkbox"
                            className="sr-only"
                            checked={reconstructionConfiguration?.use_optimisation}
                            onChange={() => {
                                dispatch(
                                    updateReconstructionConfiguration({
                                        domain,
                                        value: { use_optimisation: !reconstructionConfiguration?.use_optimisation },
                                    })
                                )
                                toast.success(`Reconstruction optimization ${!reconstructionConfiguration?.use_optimisation ? 'enabled' : 'disabled'}`)
                            }}
                        />
                        <div
                            className={`block w-14 h-8 rounded-full ${reconstructionConfiguration?.use_optimisation ? 'bg-blue-600' : 'bg-gray-300 dark:bg-gray-600'} transition-colors duration-200`}
                        ></div>
                        <div
                            className={`absolute left-1 top-1 bg-white dark:bg-gray-200 w-6 h-6 rounded-full transition-transform duration-200 transform ${reconstructionConfiguration?.use_optimisation ? 'translate-x-6' : 'translate-x-0'}`}
                        ></div>
                    </div>
                    <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">Optimize reconstruction</span>
                </label>
            </div>

            {/* Button Configuration */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Button Configuration</h3>
                <label className="flex items-center cursor-pointer">
                    <div className="relative">
                        <input
                            type="checkbox"
                            className="sr-only"
                            checked={buttonHiddenConfiguration?.hide_button}
                            onChange={() => {
                                dispatch(
                                    updateButtonHiddenConfiguration({
                                        domain,
                                        value: { hide_button: !buttonHiddenConfiguration?.hide_button },
                                    })
                                )
                                toast.success(`Aistetic sizer helper ${!buttonHiddenConfiguration?.hide_button ? 'hidden' : 'visible'}`)
                            }}
                        />
                        <div className={`block w-14 h-8 rounded-full ${buttonHiddenConfiguration?.hide_button ? 'bg-blue-600' : 'bg-gray-300 dark:bg-gray-600'} transition-colors duration-200`}></div>
                        <div
                            className={`absolute left-1 top-1 bg-white dark:bg-gray-200 w-6 h-6 rounded-full transition-transform duration-200 transform ${buttonHiddenConfiguration?.hide_button ? 'translate-x-6' : 'translate-x-0'}`}
                        ></div>
                    </div>
                    <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">Hide the Aistetic sizer helper in your website</span>
                </label>
            </div>

            {/* Widget Config */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Widget Configuration</h3>
                <div className="mb-4">
                    <ReactAce
                        mode="json"
                        theme="github"
                        value={localWidgetConfig}
                        onChange={handleWidgetConfigChange}
                        style={{ width: '100%', maxHeight: '600px' }}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        className="border border-gray-300 dark:border-gray-600 rounded-md"
                    />
                </div>
                <button className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors duration-200" onClick={updateWidgetConfig}>
                    Update
                </button>
            </div>

            {/* Measurement Config */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Measurement Configuration</h3>
                <div className="mb-4">
                    <ReactAce
                        mode="json"
                        theme="github"
                        value={JSON.stringify(measureConfiguration, null, 2)}
                        onChange={(newValue) => {
                            try {
                                dispatch(setMeasureConfiguration(JSON.parse(newValue)))
                                setUploadMeasurementErr('')
                            } catch (e) {
                                setUploadMeasurementErr('Invalid JSON format')
                            }
                        }}
                        style={{ width: '100%', maxHeight: '600px' }}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        className="border border-gray-300 dark:border-gray-600 rounded-md"
                    />
                </div>
                {uploadMeasurementErr && <p className="text-red-500 text-xs font-semibold mb-4">{uploadMeasurementErr}</p>}
                <div className="flex items-center">
                    <button
                        className={`px-4 py-2 rounded-md text-white ${measureConfiguration === '' ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'} transition-colors duration-200`}
                        disabled={measureConfiguration === ''}
                        onClick={updateMeasurementConfiguration}
                    >
                        Update
                    </button>
                </div>
            </div>

            {/* Image Cover Preview */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Image Cover Preview</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">Please make aspect ratio (height:width) = 13:8</p>

                <div className="space-y-4">
                    <input
                        type="file"
                        onChange={handleImageChange}
                        className="block w-full text-sm text-gray-700 dark:text-gray-300
                                  file:mr-4 file:py-2 file:px-4
                                  file:rounded-md file:border-0
                                  file:text-sm file:font-medium
                                  file:bg-blue-50 file:text-blue-700
                                  dark:file:bg-blue-900 dark:file:text-blue-200
                                  hover:file:bg-blue-100 dark:hover:file:bg-blue-800
                                  transition-colors duration-200"
                    />

                    {uploadedImage?.blob && (
                        <div className="mt-4 border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden">
                            <img src={uploadedImage.blob} alt="Cover preview" className="w-full h-auto object-contain" />
                        </div>
                    )}

                    <div className="mt-4">
                        <button
                            className={`px-4 py-2 rounded-md text-white ${!uploadedImage?.blob ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'} transition-colors duration-200`}
                            disabled={!uploadedImage?.blob}
                            onClick={uploadImage}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
