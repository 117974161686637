/*
 * Author: dizhong zhu
 * Date: 10/02/2025
 */

import React, { useState } from 'react'
import { X, ZoomIn } from 'lucide-react'
import { GarmentTaggingT } from 'types/garment-tagging'
import { QueueStatusBanner } from './queueStatus'

interface GarmentDetailModalProps {
    isOpen: boolean
    onClose: () => void
    garment: GarmentTaggingT
}

// Image Zoom Modal Component
const ImageZoomModal = ({ isOpen, onClose, imageUrl }: { isOpen: boolean; onClose: () => void; imageUrl: string }) => {
    const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 })
    const [showMagnifier, setShowMagnifier] = useState(false)
    const imageRef = React.useRef<HTMLImageElement>(null)

    if (!isOpen) return null

    const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
        if (!imageRef.current) return

        // Get image dimensions and position
        const { left, top, width, height } = imageRef.current.getBoundingClientRect()

        // Calculate cursor position relative to the image
        const x = ((e.clientX - left) / width) * 100
        const y = ((e.clientY - top) / height) * 100

        setMagnifierPosition({ x, y })
        setShowMagnifier(true)
    }

    const handleMouseLeave = () => {
        setShowMagnifier(false)
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-[60] p-4" onClick={handleBackgroundClick}>
            <div className="relative max-w-[95vw] max-h-[95vh]">
                <button onClick={onClose} className="absolute top-2 right-2 p-1 bg-white bg-opacity-70 hover:bg-opacity-100 rounded-full z-10">
                    <X className="h-6 w-6" />
                </button>

                <div className="relative">
                    <img
                        ref={imageRef}
                        src={imageUrl}
                        alt="Zoomed image"
                        className="max-w-full max-h-[95vh] object-contain cursor-zoom-in"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    />

                    {showMagnifier && (
                        <div
                            className="absolute w-[500px] h-[500px] border-2 border-white rounded-full overflow-hidden pointer-events-none shadow-lg"
                            style={{
                                top: `calc(${magnifierPosition.y}% - 250px)`,
                                left: `calc(${magnifierPosition.x}% - 250px)`,
                                backgroundImage: `url(${imageUrl})`,
                                backgroundPosition: `${magnifierPosition.x}% ${magnifierPosition.y}%`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '800%',
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

const GarmentDetailModal = ({ isOpen, onClose, garment }: GarmentDetailModalProps) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [zoomModalOpen, setZoomModalOpen] = useState(false)
    const [zoomedImageUrl, setZoomedImageUrl] = useState('')
    const [showUsage, setShowUsage] = useState(false)

    if (!isOpen) return null

    // Open zoom modal with the selected image
    const handleImageZoom = (imageUrl: string) => {
        setZoomedImageUrl(imageUrl)
        setZoomModalOpen(true)
    }

    // Add this handler for the background click
    const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // Only close if the click is directly on the background
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    // Add keyboard event handler for 'U' key
    React.useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'u' || e.key === 'U') {
                setShowUsage((prev) => !prev)
            }
        }

        // Add event listener when modal is open
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown)
        }

        // Clean up event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [isOpen])

    // Process tagging data to separate feature list from other properties
    const processTaggingData = () => {
        let taggingData = garment.output_tagging
        let featureList: string[] = []
        let otherTaggingData = taggingData

        // Parse if it's a string
        if (typeof taggingData === 'string') {
            try {
                taggingData = JSON.parse(taggingData)
            } catch (e) {
                return { featureList, otherTaggingData }
            }
        }

        // Extract feature_list and other properties
        if (taggingData && typeof taggingData === 'object') {
            if ('feature_list' in taggingData) {
                featureList = Array.isArray(taggingData.feature_list) ? taggingData.feature_list : []

                // Create a copy without feature_list
                const { feature_list, ...rest } = taggingData
                otherTaggingData = rest
            }
        }

        return { featureList, otherTaggingData }
    }

    const { featureList, otherTaggingData } = processTaggingData()

    // Helper function to format JSON data for display
    const formatJsonDisplay = (data: any): React.ReactNode => {
        if (!data) return 'No data available'

        // If data is a string, try to parse it as JSON
        let parsedData = data
        if (typeof data === 'string') {
            try {
                parsedData = JSON.parse(data)
            } catch (e) {
                return data // If parsing fails, return the original string
            }
        }

        const formatValue = (value: any): React.ReactNode => {
            if (value === null) return 'null'
            if (value === undefined) return 'undefined'
            if (Array.isArray(value)) {
                return (
                    <ul className="list-disc pl-8 space-y-1 mt-1">
                        {value.map((item, index) => (
                            <li key={index} className="ml-2">
                                {typeof item === 'object' && item !== null ? formatNestedObject(item) : String(item)}
                            </li>
                        ))}
                    </ul>
                )
            }
            if (typeof value === 'object' && value !== null) {
                return formatNestedObject(value)
            }
            return String(value)
        }

        // Helper function to format nested objects
        const formatNestedObject = (obj: any): React.ReactNode => {
            return (
                <div className="pl-4 mt-2 border-l-2 border-gray-200">
                    {Object.entries(obj).map(([nestedKey, nestedValue]) => (
                        <div key={nestedKey} className="mb-2">
                            <span className="font-medium">{nestedKey.replace(/_/g, ' ')}: </span>
                            {formatValue(nestedValue)}
                        </div>
                    ))}
                </div>
            )
        }

        if (typeof parsedData !== 'object' || parsedData === null) {
            return String(parsedData)
        }

        return Object.entries(parsedData).map(([key, value]) => (
            <div key={key} className="mb-2">
                <span className="font-medium">{key.replace(/_/g, ' ')}: </span>
                {formatValue(value)}
            </div>
        ))
    }

    return (
        <>
            {/* Add zoom modal */}
            <ImageZoomModal isOpen={zoomModalOpen} onClose={() => setZoomModalOpen(false)} imageUrl={zoomedImageUrl} />

            {/* Add onClick handler to the overlay div */}
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4" onClick={handleBackgroundClick}>
                {/* Add role="dialog" for accessibility */}
                <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-hidden flex flex-col" role="dialog">
                    {/* Header */}
                    <div className="flex justify-between items-center p-4 border-b">
                        <h2 className="text-xl font-semibold">Garment Details</h2>
                        <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
                            <X className="h-6 w-6" />
                        </button>
                    </div>

                    {/* Content - Modified to have separate scrollable areas */}
                    <div className="flex-1 flex overflow-hidden">
                        {/* Left Column - Images with its own scroll area */}
                        <div className="w-full lg:w-1/2 p-6 overflow-y-auto">
                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">Input Images</h3>
                                    {/* Main Image Display - Add zoom functionality */}
                                    <div
                                        className="aspect-square bg-gray-100 rounded-lg overflow-hidden relative group cursor-zoom-in"
                                        onClick={() => handleImageZoom(garment.input_imgs[selectedImageIndex])}
                                    >
                                        <img src={garment.input_imgs[selectedImageIndex]} alt={`Input image ${selectedImageIndex + 1}`} className="w-full h-full object-contain" />
                                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-20 transition-opacity">
                                            <ZoomIn className="h-10 w-10 text-white" />
                                        </div>
                                    </div>
                                    {/* Thumbnail Navigation */}
                                    {garment.input_imgs.length > 1 && (
                                        <div className="flex gap-2 overflow-x-auto pb-2">
                                            {garment.input_imgs.map((img, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => setSelectedImageIndex(index)}
                                                    className={`flex-none w-20 h-20 rounded-lg overflow-hidden border-2 
                                                        ${selectedImageIndex === index ? 'border-blue-500' : 'border-transparent'}`}
                                                >
                                                    <img src={img} alt={`Thumbnail ${index + 1}`} className="w-full h-full object-cover" />
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {/* Annotated Image - Add zoom functionality */}
                                {garment.output_measure_img && (
                                    <div className="space-y-4">
                                        <h3 className="text-lg font-semibold">Annotated Image</h3>
                                        <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden relative group cursor-zoom-in" onClick={() => handleImageZoom(garment.output_measure_img)}>
                                            <img src={garment.output_measure_img} alt="Annotated image" className="w-full h-full object-contain" />
                                            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-20 transition-opacity">
                                                <ZoomIn className="h-10 w-10 text-white" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Right Column - Details with its own scroll area */}
                        <div className="w-full lg:w-1/2 p-6 overflow-y-auto border-l">
                            <div className="space-y-6">
                                {/* Basic Info */}
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">Basic Information</h3>
                                    <div className="bg-gray-50 rounded-lg p-4 space-y-2">
                                        <div>
                                            <span className="font-medium">UUID: </span>
                                            {garment.uuid}
                                        </div>
                                        <div>
                                            <span className="font-medium">
                                                Status: <QueueStatusBanner status={garment.status} />{' '}
                                            </span>
                                            {garment.queue_position && <span className="ml-2 text-sm text-gray-600">(Queue Position: {garment.queue_position})</span>}
                                        </div>
                                        <div>
                                            <span className="font-medium">Created At: </span>
                                            {new Date(garment.created_at).toLocaleString()}
                                        </div>
                                    </div>
                                </div>

                                {/* Input Attributes */}
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">Input Attributes</h3>
                                    <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(garment.input_attribute)}</div>
                                </div>

                                {/* Measurements */}
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">Measurements</h3>
                                    <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(garment.output_measure)}</div>
                                </div>

                                {/* Tagging */}
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">Tagging</h3>
                                    <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(otherTaggingData)}</div>
                                </div>

                                {/* Description */}
                                {garment.output_description && (
                                    <div className="space-y-4">
                                        <h3 className="text-lg font-semibold">Description</h3>
                                        <div className="bg-gray-50 rounded-lg p-4">{garment.output_description}</div>
                                    </div>
                                )}

                                {/* Feature List */}
                                {featureList.length > 0 && (
                                    <div className="space-y-4">
                                        <h3 className="text-lg font-semibold">Feature List</h3>
                                        <div className="bg-gray-50 rounded-lg p-4">
                                            <ul className="list-disc pl-5 space-y-1">
                                                {featureList.map((feature, index) => (
                                                    <li key={index}>{feature}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                {/* Usage Information - Only show when U key is pressed */}
                                {garment.usage && showUsage && (
                                    <div className="space-y-4">
                                        <h3 className="text-lg font-semibold">Usage</h3>
                                        <div className="bg-gray-50 rounded-lg p-4">{formatJsonDisplay(garment.usage)}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GarmentDetailModal
