/*
 * Author: dizhong zhu
 * Date: 06/02/2025
 */

import React, { useState } from 'react'
import { Mail } from 'lucide-react'

interface EmailModalProps {
    isOpen: boolean
    onClose: () => void
    onSubmit: (email: string) => void
    title?: string
    description?: string
}

const EmailModal = ({ isOpen, onClose, onSubmit, title = 'Enter Email Address', description = 'Please enter your email address to receive the download link.' }: EmailModalProps) => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const handleSubmit = () => {
        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address')
            return
        }

        onSubmit(email)
        setEmail('')
        setError('')
        onClose()
    }

    if (!isOpen) return null

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
                <div className="flex items-center gap-2 mb-4">
                    <Mail className="h-5 w-5 text-gray-600" />
                    <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
                </div>

                <p className="text-sm text-gray-600 mb-4">{description}</p>

                <div className="mb-4">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            setError('')
                        }}
                        placeholder="Enter your email"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                </div>

                <div className="flex justify-end gap-2">
                    <button
                        onClick={() => {
                            setEmail('')
                            setError('')
                            onClose()
                        }}
                        className="px-4 py-2 text-sm border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                        Cancel
                    </button>
                    <button onClick={handleSubmit} className="px-4 py-2 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EmailModal
