/*
 * Author: dizhong zhu
 * Date: 29/01/2025
 */

import React, { useRef, useState, useCallback, useEffect } from 'react'

const calculateTooltipPosition = (triggerRect: DOMRect, tooltipElement: HTMLElement, spacing = 10): { top: number; left: number } => {
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth

    // Get tooltip dimensions
    const tooltipHeight = tooltipElement.offsetHeight
    const tooltipWidth = tooltipElement.offsetWidth

    // Default position (right of element)
    let left = triggerRect.right + spacing
    let top = triggerRect.top

    // Check if tooltip would go below viewport
    if (top + tooltipHeight > viewportHeight) {
        // If there's not enough space below, position above
        top = Math.max(spacing, viewportHeight - tooltipHeight - spacing)
    }

    // Check if tooltip would go off right edge
    if (left + tooltipWidth > viewportWidth) {
        // Position to the left of the element instead
        left = Math.max(spacing, triggerRect.left - tooltipWidth - spacing)
    }

    return { top, left }
}

export const TextWithTooltip = ({ content }: { content: string }) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const timeoutRef = useRef<number | null>(null)
    const cellRef = useRef<HTMLDivElement>(null)
    const tooltipRef = useRef<HTMLDivElement>(null)
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

    const updateTooltipPosition = useCallback(() => {
        if (cellRef.current && tooltipRef.current && showTooltip) {
            const triggerRect = cellRef.current.getBoundingClientRect()
            const position = calculateTooltipPosition(triggerRect, tooltipRef.current)
            setTooltipPosition(position)
        }
    }, [showTooltip])

    useEffect(() => {
        updateTooltipPosition()
        window.addEventListener('scroll', updateTooltipPosition)
        window.addEventListener('resize', updateTooltipPosition)

        return () => {
            window.removeEventListener('scroll', updateTooltipPosition)
            window.removeEventListener('resize', updateTooltipPosition)
        }
    }, [showTooltip, updateTooltipPosition])

    const handleMouseEnter = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        setShowTooltip(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        timeoutRef.current = window.setTimeout(() => {
            setShowTooltip(false)
        }, 1)
    }, [])

    return (
        <div ref={cellRef} className="relative cursor-pointer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="truncate max-w-[150px]">{content}</div>
            {showTooltip && content && (
                <div
                    ref={tooltipRef}
                    className="fixed z-50 bg-black bg-opacity-75 text-white p-2 rounded text-sm"
                    style={{
                        minWidth: '200px',
                        maxWidth: '400px',
                        left: tooltipPosition.left,
                        top: tooltipPosition.top,
                        wordBreak: 'break-word',
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="whitespace-pre-wrap overflow-y-auto max-h-[80vh]">{content}</div>
                </div>
            )}
        </div>
    )
}

export const JsonWithTooltip = ({ data }: { data: any }) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const timeoutRef = useRef<number | null>(null)
    const cellRef = useRef<HTMLDivElement>(null)
    const tooltipRef = useRef<HTMLDivElement>(null)
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

    if (!data) return null

    const formattedData = JSON.stringify(JSON.parse(data), null, 2)

    const updateTooltipPosition = useCallback(() => {
        if (cellRef.current && tooltipRef.current && showTooltip) {
            const triggerRect = cellRef.current.getBoundingClientRect()
            const position = calculateTooltipPosition(triggerRect, tooltipRef.current)
            setTooltipPosition(position)
        }
    }, [showTooltip])

    useEffect(() => {
        updateTooltipPosition()
        window.addEventListener('scroll', updateTooltipPosition)
        window.addEventListener('resize', updateTooltipPosition)

        return () => {
            window.removeEventListener('scroll', updateTooltipPosition)
            window.removeEventListener('resize', updateTooltipPosition)
        }
    }, [showTooltip, updateTooltipPosition])

    const handleMouseEnter = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        setShowTooltip(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        timeoutRef.current = window.setTimeout(() => {
            setShowTooltip(false)
        }, 50)
    }, [])

    return (
        <div ref={cellRef} className="relative cursor-pointer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="truncate max-w-[150px]">{formattedData}</div>
            {showTooltip && formattedData && (
                <div
                    ref={tooltipRef}
                    className="fixed z-50 bg-black bg-opacity-75 text-white p-2 rounded text-sm"
                    style={{
                        minWidth: '300px',
                        maxWidth: '600px',
                        left: tooltipPosition.left,
                        top: tooltipPosition.top,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 hover:scrollbar-thumb-gray-500" style={{ maxHeight: '40vh' }}>
                        <pre className="whitespace-pre-wrap">{formattedData}</pre>
                    </div>
                </div>
            )}
        </div>
    )
}

export const ImageWithPreview = ({ src, alt }: { src: string; alt: string }) => {
    const [showPreview, setShowPreview] = useState(false)
    const [imageError, setImageError] = useState(false)
    const timeoutRef = useRef<number | null>(null)
    const cellRef = useRef<HTMLDivElement>(null)
    const previewRef = useRef<HTMLDivElement>(null)
    const [previewPosition, setPreviewPosition] = useState({ top: 0, left: 0 })

    const updatePreviewPosition = useCallback(() => {
        if (cellRef.current && previewRef.current && showPreview) {
            const triggerRect = cellRef.current.getBoundingClientRect()
            const position = calculateTooltipPosition(triggerRect, previewRef.current)
            setPreviewPosition(position)
        }
    }, [showPreview])

    useEffect(() => {
        updatePreviewPosition()
        window.addEventListener('scroll', updatePreviewPosition)
        window.addEventListener('resize', updatePreviewPosition)

        return () => {
            window.removeEventListener('scroll', updatePreviewPosition)
            window.removeEventListener('resize', updatePreviewPosition)
        }
    }, [showPreview, updatePreviewPosition])

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
        setImageError(true)
        e.currentTarget.src = '/api/placeholder/48/48'
    }

    const handlePreviewImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.src = '/api/placeholder/512/512'
    }

    const handleMouseEnter = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        setShowPreview(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        timeoutRef.current = window.setTimeout(() => {
            setShowPreview(false)
        }, 1)
    }, [])

    if (!src || imageError) {
        return (
            <div className="relative">
                <img src="/api/placeholder/48/48" alt={alt} className="w-12 h-12 object-cover rounded" />
            </div>
        )
    }

    return (
        <div ref={cellRef} className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img src={src} alt={alt} className="w-12 h-12 object-cover rounded cursor-pointer" onError={handleImageError} />
            {showPreview && (
                <div
                    ref={previewRef}
                    className="fixed z-50 bg-white p-2 rounded-lg shadow-xl"
                    style={{
                        width: '320px',
                        height: '320px',
                        left: previewPosition.left,
                        top: previewPosition.top,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <img src={src} alt={alt} className="w-full h-full object-contain" onError={handlePreviewImageError} />
                </div>
            )}
        </div>
    )
}
