/*
 * Author: dizhong zhu
 * Date: 10/03/2023
 */

import { useState, useEffect } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import { getTenants } from 'store/tenant'
import { ModalCreate } from './Modals'
import { TENANTS_ROUTE } from 'routes/path'
import { format, isBefore, subMonths } from 'date-fns'
import PageLoader from 'components/PageLoader'
import { setShowActiveTenants } from 'store/settings'

export const Tenants = () => {
    const dispatch = useAppDispatch()

    const { tenants, isLoadingTenants } = useAppSelector((state) => state.tenant)
    const { showActiveTenants } = useAppSelector((state) => state.settings)
    const [showCreate, setShowCreate] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getTenants())
    }, [showActiveTenants])

    const handleCreateTenant = async () => {
        setShowCreate(false)
        dispatch(getTenants())
    }

    const handleShowActiveTenants = (active: boolean) => {
        dispatch(setShowActiveTenants(active))
    }

    const handleCardClick = (tenant: any, index: any) => {
        navigate(`${TENANTS_ROUTE}/${index}`, { state: { domain: tenant.domain } })
    }

    const getLastActivityColor = (lastActivity: string) => {
        const lastActivityDate = new Date(lastActivity)
        const oneMonthAgo = subMonths(new Date(), 1)
        return isBefore(lastActivityDate, oneMonthAgo) ? 'red' : 'green'
    }

    return (
        <PageLoader open={isLoadingTenants}>
            <div className="w-full px-2">
                <div className="mb-4 flex items-center">
                    <div className="w-full">
                        <Form.Group controlId="activeTenantsCheckbox" className="flex items-center">
                            <Form.Check
                                type="checkbox"
                                label={<span className="text-lg font-bold">Show Active Tenants Only</span>}
                                checked={showActiveTenants}
                                onChange={(e) => handleShowActiveTenants(e.target.checked)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {tenants.map((tenant, index) => (
                        <div key={index}>
                            <div className="h-full border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 cursor-pointer bg-white" onClick={() => handleCardClick(tenant, index)}>
                                <div className="px-4 py-3 border-b flex justify-between items-center">
                                    <span className="font-medium">{tenant.domain}</span>
                                </div>
                                <div className="p-4">
                                    <h5 className="text-xl font-semibold mb-3">{tenant.name}</h5>
                                    <h6 className="text-gray-600 mb-3">{tenant.domain}</h6>
                                    <p className="mb-4">{tenant.description}</p>
                                    <p className={`${getLastActivityColor(tenant.last_activity) === 'red' ? 'text-red-600' : 'text-green-600'}`}>
                                        Last activity: {format(new Date(tenant.last_activity), 'yyyy-MM-dd HH:mm:ss')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div>
                        <Button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded" onClick={() => setShowCreate(true)}>
                            Add Tenant
                        </Button>
                    </div>
                </div>
                <ModalCreate show={showCreate} onHide={handleCreateTenant} />
            </div>
        </PageLoader>
    )
}
