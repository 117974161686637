/*
 * Author: dizhong zhu
 * Date: 10/10/2024
 */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { delGarmentTaggingRequest, downloadGarmentTaggingRequest, listGarmentTaggingRequest, rerunGarmentTaggingRequest } from 'services/garment-tagging'
import { GarmentTaggingT, GarmentTaggingResponseT, PaginatedGarmentTaggingT, QueryParamsT, GarmentTaggingRequestT, GarmentTaggingDownloadRequestT } from 'types/garment-tagging'

interface GarmentTaggingState {
    garmentTaggingItems: GarmentTaggingT[]
    totalItems: number
    totalCompletedItems: number
    totalPages: number
    currentPage: number
    isGarmentTaggingLoading: boolean
    errors: any[]
}

export const initialState: GarmentTaggingState = {
    garmentTaggingItems: [],
    totalItems: 0,
    totalCompletedItems: 0,
    totalPages: 0,
    currentPage: 1,
    isGarmentTaggingLoading: false,
    errors: [],
}

interface ListGarmentTaggingParams {
    domain: string
    queryParams: QueryParamsT
}

export const listGarmentTagging = createAsyncThunk<PaginatedGarmentTaggingT, ListGarmentTaggingParams>('garment-tagging/list', async ({ domain, queryParams }, { dispatch }) => {
    dispatch(setGarmentTaggingIsLoading(true))
    return listGarmentTaggingRequest(domain, queryParams)
        .then((res: GarmentTaggingResponseT) => {
            const paginatedData = res.data
            let index = 0
            const items = paginatedData.items.map((item: GarmentTaggingT) => ({
                ...item,
                ID: index++,
            }))

            dispatch(
                setGarmentTaggingData({
                    items,
                    totalItems: paginatedData.total_items,
                    totalCompletedItems: paginatedData.total_completed_items,
                    totalPages: paginatedData.total_pages,
                    currentPage: paginatedData.current_page,
                })
            )

            return paginatedData
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
            throw err
        })
        .finally(() => dispatch(setGarmentTaggingIsLoading(false)))
})

export const rerunGarmentTagging = createAsyncThunk<void, any>('garment-tagging/rerun', async (data: GarmentTaggingRequestT, { dispatch }) => {
    return rerunGarmentTaggingRequest(data).catch((err: any) => {
        dispatch(setError([err.response.data.message]))
        throw err
    })
})

export const delGarmentTagging = createAsyncThunk<void, any>('garment-tagging/delete', async (data: GarmentTaggingRequestT, { dispatch }) => {
    return delGarmentTaggingRequest(data).catch((err: any) => {
        dispatch(setError([err.response.data.message]))
        throw err
    })
})

export const downloadGarmentTagging = createAsyncThunk<void, any>('garment-tagging/download', async (data: GarmentTaggingDownloadRequestT, { dispatch }) => {
    return downloadGarmentTaggingRequest(data).catch((err: any) => {
        dispatch(setError([err.response.data.message]))
        throw err
    })
})

export const garmentTaggingSlice = createSlice({
    name: 'garment-tagging',
    initialState,
    reducers: {
        setGarmentTaggingData: (
            state,
            {
                payload,
            }: PayloadAction<{
                items: GarmentTaggingT[]
                totalItems: number
                totalCompletedItems: number
                totalPages: number
                currentPage: number
            }>
        ) => {
            state.garmentTaggingItems = payload.items
            state.totalItems = payload.totalItems
            state.totalCompletedItems = payload.totalCompletedItems
            state.totalPages = payload.totalPages
            state.currentPage = payload.currentPage
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload
        },
        setGarmentTaggingIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isGarmentTaggingLoading = payload
        },
    },
})

export const { setGarmentTaggingData, setGarmentTaggingIsLoading, setError } = garmentTaggingSlice.actions

export default garmentTaggingSlice.reducer
