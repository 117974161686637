/*
 * Author: dizhong zhu
 * Date: 18/03/2025
 */

import React, { useEffect, useState } from 'react'
import ReactAce from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/webpack-resolver'
import Loader from 'components/Loader'
import toast from 'react-hot-toast'

interface Props {
    domain: string
    nodeConfig: any
    onUpdateNodeConfig: (config: any) => Promise<void>
}

export function NodeConfiguration({ domain, nodeConfig, onUpdateNodeConfig }: Props) {
    const [localNodeConfig, setLocalNodeConfig] = useState('{}')
    const [uploadingNodeConfig, setUploadingNodeConfig] = useState(false)
    const [uploadNodeConfigErr, setUploadNodeConfigErr] = useState('')

    useEffect(() => {
        setLocalNodeConfig(JSON.stringify(nodeConfig, null, 2))
    }, [nodeConfig])

    const handleNodeConfigChange = (newConfig: any) => {
        setLocalNodeConfig(newConfig)
    }

    const updateNodeConfig = () => {
        try {
            const parsedConfig = JSON.parse(localNodeConfig)
            setUploadingNodeConfig(true)
            onUpdateNodeConfig(parsedConfig)
                .then(() => {
                    toast.success('Node configuration updated')
                })
                .catch((error) => {
                    toast.error('Failed to update node configuration')
                    console.error(error)
                })
                .finally(() => {
                    setUploadingNodeConfig(false)
                })
        } catch (error) {
            toast.error('Invalid JSON format')
        }
    }

    const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader()
        const { files } = event.target

        if (files?.[0]) {
            fileReader.readAsText(files[0], 'UTF-8')
            fileReader.onload = (e: any) => {
                const content = e.target.result
                try {
                    const parsedContent = JSON.parse(content)
                    setLocalNodeConfig(JSON.stringify(parsedContent, null, 2))
                    setUploadNodeConfigErr('')
                } catch (e) {
                    setUploadNodeConfigErr('Please upload a valid JSON file')
                }
            }
        }
    }

    return (
        <div className="bold-form-labels">
            <div className="mb-3 mt-6">
                <label htmlFor="node_config" className="block font-semibold text-gray-700 mb-2">
                    Agent Node Configuration
                </label>
                <div className="mt-2">
                    <ReactAce
                        mode="json"
                        theme="github"
                        value={localNodeConfig}
                        onChange={handleNodeConfigChange}
                        style={{ width: '100%', borderRadius: '0.375rem' }}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                    />
                </div>
                <div className="mt-3 mb-3 flex items-center gap-3">
                    <button
                        className={`bg-blue-600 hover:bg-blue-700 text-white font-medium py-1 px-3 rounded-md transition-colors ${uploadingNodeConfig ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={updateNodeConfig}
                        disabled={uploadingNodeConfig}
                    >
                        {uploadingNodeConfig ? 'Updating...' : 'Update'}
                    </button>
                    {uploadingNodeConfig && <Loader />}
                </div>
            </div>

            <div className="mb-5">
                <label htmlFor="node_config_file" className="block font-semibold text-gray-700 mb-2">
                    Import Node Configuration from File
                </label>
                <input
                    type="file"
                    id="node_config_file"
                    onChange={readFile}
                    className="mt-1 block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-md file:border-0
                    file:text-sm file:font-medium
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100"
                />
                {uploadNodeConfigErr && <p className="text-red-500 font-medium text-xs mt-2">{uploadNodeConfigErr}</p>}
            </div>
        </div>
    )
}
