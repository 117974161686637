/*
 * Author: dizhong zhu
 * Date: 10/02/2025
 */

import React from 'react'
import { AlertCircle } from 'lucide-react'
import { createRoot } from 'react-dom/client'

interface DialogProps {
    title: string
    message: string
    confirmText?: string
    cancelText?: string
}

const Dialog = ({
    title,
    message,
    confirmText = 'Delete',
    cancelText = 'Cancel',
    onConfirm,
    onCancel,
}: DialogProps & {
    onConfirm: () => void
    onCancel: () => void
}) => {
    // Handle backdrop click
    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            onCancel()
        }
    }

    return (
        // Added onClick handler to the backdrop
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]" onClick={handleBackdropClick}>
            <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
                <div className="flex items-center gap-3 mb-4">
                    <AlertCircle className="h-6 w-6 text-red-500" />
                    <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
                </div>

                <p className="text-sm text-gray-600 mb-6">{message}</p>

                <div className="flex justify-end gap-3">
                    <button onClick={onCancel} className="px-4 py-2 text-sm border border-gray-300 rounded-md hover:bg-gray-50">
                        {cancelText}
                    </button>
                    <button onClick={onConfirm} className="px-4 py-2 text-sm bg-red-600 text-white rounded-md hover:bg-red-700">
                        {confirmText}
                    </button>
                </div>
            </div>
        </div>
    )
}

export const showConfirmDialog = (props: DialogProps): Promise<boolean> => {
    return new Promise((resolve) => {
        const container = document.createElement('div')
        document.body.appendChild(container)
        const root = createRoot(container)

        const cleanup = () => {
            root.unmount()
            container.remove()
        }

        const handleConfirm = () => {
            cleanup()
            resolve(true)
        }

        const handleCancel = () => {
            cleanup()
            resolve(false)
        }

        root.render(<Dialog {...props} onConfirm={handleConfirm} onCancel={handleCancel} />)
    })
}
