/*
 * Author: dizhong zhu
 * Date: 30/01/2025
 */

/*
 * Author: dizhong zhu
 * Date: 30/01/2025
 */

import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { format, startOfWeek, startOfMonth, endOfWeek, endOfMonth, subWeeks, subMonths } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import { X } from 'lucide-react'

enum DateRange {
    Today = 0,
    Yesterday = 1,
    Week = 2,
    Month = 3,
    Quarter = 4,
    HalfYear = 5,
    Year = 6,
    ThisWeek = 7,
    LastWeek = 8,
    ThisMonth = 9,
    LastMonth = 10,
    ShowAll = 11,
    Customise = 1000,
}

const paginationItems = [
    { range: DateRange.Today, label: 'Today' },
    { range: DateRange.Yesterday, label: 'Yesterday' },
    { range: DateRange.ThisWeek, label: 'This Week' },
    { range: DateRange.LastWeek, label: 'Last Week' },
    { range: DateRange.ThisMonth, label: 'This Month' },
    { range: DateRange.LastMonth, label: 'Last Month' },
    { range: DateRange.Week, label: '7D' },
    { range: DateRange.Month, label: '30D' },
    { range: DateRange.Quarter, label: '3M' },
    { range: DateRange.HalfYear, label: '6M' },
    { range: DateRange.Year, label: '12M' },
    { range: DateRange.ShowAll, label: 'Show All' },
    { range: DateRange.Customise, label: 'Custom' },
]

interface Props {
    startDate: Date | undefined
    OnStartDateChange: (date: any) => void
    endDate: Date | undefined
    OnEndDateChange: (date: any) => void
    OnStartEndDateChange?: (startDate: any, endDate: any) => void
}

export function TimeFilters({ startDate, OnStartDateChange, endDate, OnEndDateChange, OnStartEndDateChange }: Props) {
    const [activePage, setActivePage] = useState(DateRange.ShowAll)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [showTooltip, setShowTooltip] = useState<DateRange | null>(null)
    const [datePickerPosition, setDatePickerPosition] = useState({ top: 0, left: 0 })

    const customDateTarget = useRef<HTMLButtonElement>(null)

    // Update datepicker position when shown
    useEffect(() => {
        if (showDatePicker && customDateTarget.current) {
            const buttonRect = customDateTarget.current.getBoundingClientRect()
            setDatePickerPosition({
                top: buttonRect.bottom + window.scrollY,
                left: Math.max(0, buttonRect.left + window.scrollX - 100), // Center the picker relative to button
            })
        }
    }, [showDatePicker])

    const closeDatePicker = () => {
        setShowDatePicker(false)
    }

    const handleEndDatePickerChange = (date: Date | null) => {
        OnEndDateChange(date)
        setShowDatePicker(false)
    }

    const handlePageClick = (range: DateRange) => {
        if (range === DateRange.Customise) {
            setShowDatePicker(true)
        } else {
            const endDate = getEndDate(range)
            const startDate = getStartDate(range)

            if (OnStartEndDateChange) {
                OnStartEndDateChange(startDate, endDate)
            } else {
                OnStartDateChange(startDate)
                OnEndDateChange(endDate)
            }
            setShowDatePicker(false)
        }

        setActivePage(range)
    }

    const getTooltipContent = (range: DateRange) => {
        const endDate = getEndDate(range)
        const startDate = getStartDate(range)
        switch (range) {
            case DateRange.Today:
            case DateRange.Yesterday:
                return `${format(startDate, 'dd/MMM/yyyy')} (${getTimeZoneString()})`
            case DateRange.Week:
            case DateRange.Month:
            case DateRange.Quarter:
            case DateRange.HalfYear:
            case DateRange.Year:
            case DateRange.ThisWeek:
            case DateRange.LastWeek:
            case DateRange.ThisMonth:
            case DateRange.LastMonth:
                return `${format(startDate, 'dd/MMM/yyyy')} - ${format(endDate, 'dd/MMM/yyyy')} (${getTimeZoneString()})`
            case DateRange.ShowAll:
                return 'Show All'
            default:
                return ''
        }
    }

    const getTimeZoneString = () => {
        const now = new Date()
        return `GMT${now.getTimezoneOffset() < 0 ? '+' : ''}${now.getTimezoneOffset() / -60}`
    }

    const getEndDate = (range: DateRange) => {
        const endDate = new Date()
        switch (range) {
            case DateRange.Today:
            case DateRange.Week:
            case DateRange.Month:
            case DateRange.Quarter:
            case DateRange.HalfYear:
            case DateRange.Year:
            case DateRange.ThisWeek:
            case DateRange.ThisMonth:
            case DateRange.ShowAll:
                break
            case DateRange.Yesterday:
                endDate.setHours(0, 0, 0, 0)
                break
            case DateRange.LastWeek:
                endDate.setHours(0, 0, 0, 0)
                return endOfWeek(subWeeks(endDate, 1), { weekStartsOn: 1 })
            case DateRange.LastMonth:
                endDate.setHours(0, 0, 0, 0)
                return endOfMonth(subMonths(endDate, 1))
            default:
                return endDate
        }

        return endDate
    }

    const getStartDate = (range: DateRange) => {
        const startDate = new Date()
        startDate.setHours(0, 0, 0, 0)
        switch (range) {
            case DateRange.Today:
                break
            case DateRange.Yesterday:
                startDate.setDate(startDate.getDate() - 1)
                break
            case DateRange.Week:
                startDate.setDate(startDate.getDate() - 7)
                break
            case DateRange.Month:
                startDate.setMonth(startDate.getMonth() - 1)
                break
            case DateRange.Quarter:
                startDate.setMonth(startDate.getMonth() - 3)
                break
            case DateRange.HalfYear:
                startDate.setMonth(startDate.getMonth() - 6)
                break
            case DateRange.Year:
                startDate.setFullYear(startDate.getFullYear() - 1)
                break
            case DateRange.ShowAll:
                return new Date(0)
            case DateRange.ThisWeek:
                return startOfWeek(startDate, { weekStartsOn: 1 })
            case DateRange.LastWeek:
                return startOfWeek(subWeeks(startDate, 1), { weekStartsOn: 1 })
            case DateRange.ThisMonth:
                return startOfMonth(startDate)
            case DateRange.LastMonth:
                return startOfMonth(subMonths(startDate, 1))
            default:
                return ''
        }

        return startDate
    }

    return (
        <div className="w-full">
            <div className="inline-flex flex-wrap">
                {paginationItems.map((item) => (
                    <div key={item.range} className="relative" onMouseEnter={() => setShowTooltip(item.range)} onMouseLeave={() => setShowTooltip(null)}>
                        <button
                            className={`px-3 py-1.5 text-sm border border-gray-300 transition-colors first:rounded-l-md last:rounded-r-md -ml-[1px] first:ml-0 ${
                                activePage === item.range ? 'bg-blue-600 text-white hover:bg-blue-700 border-blue-600 z-10' : 'bg-white text-gray-700 hover:bg-gray-50'
                            }`}
                            onClick={() => handlePageClick(item.range)}
                            ref={item.range === DateRange.Customise ? customDateTarget : undefined}
                        >
                            {item.label}
                        </button>
                        {showTooltip === item.range && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded whitespace-nowrap">
                                {getTooltipContent(item.range)}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {showDatePicker && (
                <div
                    className="fixed bg-white rounded-lg shadow-lg border border-gray-200 p-4"
                    style={{
                        top: datePickerPosition.top,
                        left: datePickerPosition.left,
                        zIndex: 1000,
                    }}
                >
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-medium">Select Date Range</h3>
                        <button onClick={closeDatePicker} className="text-gray-500 hover:text-gray-700">
                            <X className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Start Time:</label>
                            <DatePicker
                                selected={startDate}
                                onChange={OnStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">End Time:</label>
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDatePickerChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="End Date"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TimeFilters
