import React, { useEffect, useState } from 'react'
import { TenantSideBar } from 'widgets/TenantSideBar'
import { useAppDispatch, useAppSelector } from 'store'
import { getPageViews, getTransactions, getTransactionStats, importTransactions } from 'store/analytics'
import { Col, Container, Row } from 'react-bootstrap'
import 'views/css/page_control.css'
import { TransactionTable } from './TransactionTable'
import { TimeFilters } from 'widgets/TimeFilter'
import { setSelectedDomain } from 'store/settings'
import { ClothStatChart } from './ClothStat'
import { MetricChart } from './MetricChart'
import PageLoader from '../../components/PageLoader' // Import the action from the 'setting' slice

export function Dashboard() {
    const dispatch = useAppDispatch()

    const [startDate, setStartDate] = useState(new Date(0))
    const [endDate, setEndDate] = useState(new Date())
    const { tenants } = useAppSelector((state) => state.tenant)
    const { transactionStat, transactions, isLoadingAnalytics, isLoadingTransaction } = useAppSelector((state) => state.analytics)

    const { selectedDomain } = useAppSelector((state) => state.settings)

    useEffect(() => {
        handleTenantClick(selectedDomain || tenants[0]?.domain)
    }, [tenants, startDate, endDate])

    const handleTenantClick = async (domain: string) => {
        console.log('click domain: ' + domain)
        dispatch(setSelectedDomain(domain))
        dispatch(getTransactionStats({ domain, startDate, endDate }))
        dispatch(getTransactions({ domain, startDate, endDate }))
        dispatch(getPageViews({ domain, startDate, endDate }))
    }

    const handleStartDateChange = (date: Date) => {
        setStartDate(date)
    }

    const handleEndDateChange = (date: Date) => {
        setEndDate(date)
    }

    const handleImport = async (file: File) => {
        console.log('Selected file:', file.name)
        try {
            await dispatch(importTransactions({ domain: selectedDomain, file })).unwrap()
            handleTenantClick(selectedDomain)
        } catch (error) {
            console.error('Import failed:', error)
        }
    }

    return (
        <PageLoader open={isLoadingAnalytics && isLoadingTransaction}>
            <Container fluid className="h-100">
                <Row className="h-100">
                    <Col md={2} className="d-flex flex-column h-100 page-height">
                        <TenantSideBar tenants={tenants} currentDomain={selectedDomain} OnTenantClick={handleTenantClick} />
                    </Col>

                    <Col md={10} className="h-100">
                        <>
                            <TimeFilters startDate={startDate} OnStartDateChange={handleStartDateChange} endDate={endDate} OnEndDateChange={handleEndDateChange} />
                        </>
                        <div className="page-height overflow-auto">
                            {!isLoadingAnalytics && selectedDomain !== '' && <MetricChart stats={transactionStat} />}
                            {!isLoadingTransaction && selectedDomain !== '' && <TransactionTable transactionItems={transactions} handleImport={handleImport} />}
                            {/*{!isLoadingAnalytics && selectedDomain !== '' && <ClothStatChart clothStat={transactionStat?.clothStat} />}*/}
                        </div>
                    </Col>
                </Row>
            </Container>
        </PageLoader>
    )
}
