/*
 * Author: dizhong zhu
 * Date: 03/04/2023
 */

export const SIGN_IN = '/login'
export const SIGN_UP = '/sign-up'
export const SIGN_OUT = '/sign-out'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const PRE_SIGN_IN = '/'

// Dashboard routes
export const DASHBOARD_BASE = '/dashboard'

// Tenant routes
export const TENANT_GENERAL = 'general'
export const TENANT_BILLING = 'billing'
export const TENANT_SIZECHART = 'size-chart'
export const TENANT_AI_LISTING = 'ai-listing'
