/*
 * Author: dizhong zhu
 * Date: 18/03/2025
 */

import { useEffect } from 'react'
import { NodeConfiguration } from './nodeConfig'
import { TaggingSchema } from './taggingSchema'
import { useAppDispatch, useAppSelector } from 'store'
import Loader from 'components/Loader'
import { getAIListingConfiguration, updateNodeConfiguration, updateTaggingSchema } from 'store/configurations/ai-listing'

interface Props {
    domain: string
}

export function AIListSetting({ domain }: Props) {
    const dispatch = useAppDispatch()
    const { nodeConfiguration, taggingSchema, isLoadingAIListing } = useAppSelector((state) => state.aiListingConfiguration)

    useEffect(() => {
        dispatch(getAIListingConfiguration(domain))
    }, [domain])

    const handleUpdateNodeConfig = async (config: any) => {
        dispatch(updateNodeConfiguration({ domain, value: config }))
    }

    const handleUpdateTaggingSchema = async (schema: any) => {
        dispatch(updateTaggingSchema({ domain, value: schema }))
    }

    return (
        <div className="space-y-6">
            <div className="bg-white shadow-sm rounded-lg p-6">
                <div className="space-y-8">
                    <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Agent Configuration</h3>
                        <p className="text-sm text-gray-500 mb-6">Configure the AI agent nodes and their behavior. These settings control how the AI agents interact with your system.</p>
                        <NodeConfiguration domain={domain} nodeConfig={nodeConfiguration} onUpdateNodeConfig={handleUpdateNodeConfig} />
                    </div>

                    <div className="pt-8 border-t border-gray-200">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Tagging Schema</h3>
                        <p className="text-sm text-gray-500 mb-6">Configure the tagging schema for AI listing classification. This schema defines how content is tagged and categorized.</p>
                        {/* <TaggingSchema domain={domain} taggingSchema={taggingSchema} onUpdateTaggingSchema={handleUpdateTaggingSchema} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
