import React, { useState } from 'react'
import { ModalDelete } from '../../Modals'
import { useNavigate } from 'react-router-dom'
import { DisplaySuccess } from 'widgets/DisplayAlert'
import { TENANTS_ROUTE } from 'routes/path'

interface Props {
    domain: string
}

export const DangerousZone: React.FC<Props> = ({ domain }) => {
    const [showDelete, setShowDelete] = useState(false)

    const navigate = useNavigate()

    const BeforeDeleteTenant = async () => {
        setShowDelete(true)
    }

    const HandleHide = async () => {
        setShowDelete(false)
    }

    const HandleDeleteTenant = async () => {
        setShowDelete(false)
        navigate(`${TENANTS_ROUTE}`)
        DisplaySuccess(`Tenant ${domain} deleted successfully`)
    }

    return (
        <div className="w-full">
            <div className="font-medium">
                <div className="border border-red-300 dark:border-red-700 rounded-lg p-4 bg-red-50 dark:bg-red-900/20 flex justify-between items-center">
                    <div className="space-y-1">
                        <label className="text-gray-900 dark:text-gray-100 font-semibold">Delete this tenant</label>
                        <p className="text-red-600 dark:text-red-400 text-sm">All the scan data, configuration will be erased once you delete the tenant, and this is not reversible.</p>
                    </div>
                    <button
                        className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-medium rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
                        onClick={BeforeDeleteTenant}
                    >
                        Delete Tenant
                    </button>
                </div>
            </div>
            <ModalDelete show={showDelete} domain={domain} onHide={HandleHide} onDelete={HandleDeleteTenant} />
        </div>
    )
}
