import http from './http'
import { ListConfigurationResponseT } from 'types/configurations'

export async function getConfigurationsRequest(domain: string): Promise<ListConfigurationResponseT> {
    return (await http.get(`/merchant/tenant/configurations?domain=${domain}`)).data
}

export async function updateConfigurationRequest(domain: string, data: any): Promise<ListConfigurationResponseT> {
    return (await http.patch('/merchant/tenant/configurations', data, { headers: { DOMAIN: domain } })).data
}
