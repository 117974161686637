import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    ConfigurationT,
    ListConfigurationResponseT,
    VideoConfigurationT,
    ReconstructionConfigurationT,
    HideButtonConfigurationT,
    EmailConfigurationT,
    ImageCoverConfigurationT,
} from 'types/configurations'
import { getConfigurationsRequest, updateConfigurationRequest } from 'services/configuration'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

interface ConfigurationState {
    configurations: ConfigurationT[]
    videoConfiguration: VideoConfigurationT | null
    reconstructionConfiguration: ReconstructionConfigurationT | null
    buttonHiddenConfiguration: HideButtonConfigurationT | null
    widgetConfiguration: any | null
    measureConfiguration: any | null
    emailConfiguration: EmailConfigurationT | null
    imageCoverConfiguration: ImageCoverConfigurationT | null
    isUpdatingEmailConfiguration: boolean
    isLoadingConfigurations: boolean
    configurationsError: any[]
}

export const initialState: ConfigurationState = {
    configurations: [],
    videoConfiguration: null,
    reconstructionConfiguration: null,
    buttonHiddenConfiguration: null,
    widgetConfiguration: null,
    measureConfiguration: null,
    emailConfiguration: null,
    imageCoverConfiguration: null,
    isUpdatingEmailConfiguration: false,
    isLoadingConfigurations: true,
    configurationsError: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getGeneralConfigurations = createAsyncThunk<ConfigurationT[], string>('configuration/view', async (domain: string, { dispatch }) => {
    dispatch(isLoadingConfigurations(true))

    return getConfigurationsRequest(domain)
        .then((res: ListConfigurationResponseT) => {
            dispatch(setConfigurations(res.data))

            const videoConfig = res.data.find((config: ConfigurationT) => config.name === 'video_option')
            dispatch(setVideoConfiguration(videoConfig?.value as any))

            const reconstructionConfig = res.data.find((config: ConfigurationT) => config.name === 'reconstruction_option')
            dispatch(setReconstructionConfiguration(reconstructionConfig?.value as any))

            const buttonHiddenConfig = res.data.find((config: ConfigurationT) => config.name === 'button_option')
            dispatch(setButtonHiddenConfiguration(buttonHiddenConfig?.value as any))

            const widgetConfig = res.data.find((config: ConfigurationT) => config.name === 'widget_config')
            dispatch(setWidgetConfiguration(widgetConfig?.value as any))

            const measureConfig = res.data.find((config: ConfigurationT) => config.name === 'measure_config')
            dispatch(setMeasureConfiguration(measureConfig?.value as any))

            const emailConfig = res.data.find((config: ConfigurationT) => config.name === 'email_config')
            dispatch(setEmailConfiguration(emailConfig?.value as any))

            const imageCoverConfig = res.data.find((config: ConfigurationT) => config.name === 'image_cover')
            dispatch(setImageCoverConfiguration(imageCoverConfig?.value as any))

            return res.data
        })
        .catch((err: any) => {
            dispatch(setConfigurationsError([err.response.data.message]))
        })
        .finally(() => dispatch(isLoadingConfigurations(false))) as Promise<ConfigurationT[]>
})

interface ConfigurationPayloadT {
    domain: string
    value: any
}

export const updateVideoConfiguration = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/video', async ({ domain, value }: any, { dispatch }) => {
    dispatch(updateConfiguration({ domain, data: { name: 'video_option', value: value } }))
    return {}
})

export const updateReconstructionConfiguration = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/reconstruction', async ({ domain, value }: any, { dispatch }) => {
    dispatch(updateConfiguration({ domain, data: { name: 'reconstruction_option', value: value } }))
    return {}
})

export const updateButtonHiddenConfiguration = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/button_hidden', async ({ domain, value }: any, { dispatch }) => {
    dispatch(updateConfiguration({ domain, data: { name: 'button_option', value: value } }))
    return {}
})

export const updateEmailConfiguration = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/email', async ({ domain, value }: any, { dispatch }) => {
    setIsUpdatingEmailConfiguration(true)
    dispatch(updateConfiguration({ domain, data: { name: 'email_config', value: value } })).finally(() => setIsUpdatingEmailConfiguration(false))
    return {}
})

export const updateWidgetConfiguration = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/widget', async ({ domain, value }: any, { dispatch }) => {
    dispatch(updateConfiguration({ domain, data: { name: 'widget_config', value: value } }))
    return {}
})

export const updateImageCoverConfiguration = createAsyncThunk<any, ConfigurationPayloadT>('configuration/update/image_cover', async ({ domain, value }: any, { dispatch }) => {
    dispatch(updateConfiguration({ domain, data: { name: 'image_cover', value: value } }))
    return {}
})

export const updateConfiguration = createAsyncThunk<any, any>('configuration/update', async (i: any, { dispatch }) => {
    dispatch(isLoadingConfigurations(true))
    dispatch(setConfigurationsError([]))

    const { domain, data } = i

    return updateConfigurationRequest(domain, data)
        .then((response: any) => {
            dispatch(getGeneralConfigurations(domain))
            return response.data
        })
        .catch((err: any) => {
            dispatch(setConfigurationsError([err.response.data.message]))
        })
        .finally(() => dispatch(isLoadingConfigurations(false))) as Promise<any>
})

export const generalConfigurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        setConfigurations: (state, { payload }: PayloadAction<ConfigurationT[]>) => {
            state.configurations = payload
        },
        setVideoConfiguration: (state, { payload }: PayloadAction<VideoConfigurationT>) => {
            state.videoConfiguration = payload
        },
        setReconstructionConfiguration: (state, { payload }: PayloadAction<ReconstructionConfigurationT>) => {
            state.reconstructionConfiguration = payload
        },
        setButtonHiddenConfiguration: (state, { payload }: PayloadAction<HideButtonConfigurationT>) => {
            state.buttonHiddenConfiguration = payload
        },
        setWidgetConfiguration: (state, { payload }: PayloadAction<any>) => {
            state.widgetConfiguration = payload
        },
        setMeasureConfiguration: (state, { payload }: PayloadAction<any>) => {
            state.measureConfiguration = payload
        },
        setEmailConfiguration: (state, { payload }: PayloadAction<EmailConfigurationT>) => {
            state.emailConfiguration = payload
        },
        setImageCoverConfiguration: (state, { payload }: PayloadAction<ImageCoverConfigurationT>) => {
            state.imageCoverConfiguration = payload
        },
        setIsUpdatingEmailConfiguration: (state, { payload }: PayloadAction<boolean>) => {
            state.isUpdatingEmailConfiguration = payload
        },
        setConfigurationsError: (state, { payload }: PayloadAction<any>) => {
            state.configurationsError = payload
        },
        isLoadingConfigurations: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoadingConfigurations = payload
        },
    },
})

export const {
    setConfigurations,
    isLoadingConfigurations,
    setConfigurationsError,
    setVideoConfiguration,
    setReconstructionConfiguration,
    setButtonHiddenConfiguration,
    setWidgetConfiguration,
    setMeasureConfiguration,
    setEmailConfiguration,
    setIsUpdatingEmailConfiguration,
    setImageCoverConfiguration,
} = generalConfigurationSlice.actions

export default generalConfigurationSlice.reducer
